<template >
  <div id="app" tabindex="1" class="sm:max-h-96 sm:!min-h-[80%] sm:max-w-lg w-full sm:border sm:border-black sm:shadow-lg overflow-auto relative">
    <portal-target name="modal" />
     <headerElem   />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    <FooterElem  />
 </div> 
</template>

<script>
import HeaderElem from '@/components/Header'
import FooterElem from '@/components/Footer'

export default {
  name: 'App',
  data: function() {
    return {
    };
  },   
  mounted() {
    let uniqid = Math.random().toString(36).substr(2, 9);
    if(!localStorage.userid) {
      localStorage.userid = uniqid;
    } else {
      uniqid = localStorage.userid;
    }
    mixpanel.identify(uniqid);
  },
  components: {
    HeaderElem,
    FooterElem,
  }
}
</script>