import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "*",
      redirect: "/404",
    },
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue"),
    },
    {
      path: "/:_profil",
      name: "status",
      component: () => import("./views/Home.vue"),
    },
    {
      path: "/:_profil/:_theme1",
      name: "status",
      component: () => import("./views/Home.vue"),
    },
    {
      path: "/:_profil/:_theme1/:_theme2",
      name: "status",
      component: () => import("./views/Home.vue"),
    },
  ],
});

export default router;
