<template>
  <footer class="relative z-20">
    <div class="flex justify-end relative cursor-pointer" tabindex="1">
      <div
        class="absolute right-0 -top-8 h-10 w-16 justify-start hidden"
        @click="changeLang()"
        :class="{ '!flex ': open }"
      >
        <img class="h-10" :src="`/${lang2}.png`" />
      </div>
      <div class="relative h-10 w-16 justify-start" @click="openLang()">
        <img class="h-10" :src="`/${lang}.png`" />
        <img class="h-2 absolute right-2 top-5" :src="`/Arrow.png`" />
      </div>
    </div>
  </footer>
</template>

<script>
import { useSound } from "@vueuse/sound";
import buttonClick from "../assets/click.mp3";
import gameStart from "../assets/open.mp3";

export default {
  data: function () {
    return {
      open: false,
      lang: undefined,
      lang2: "en",
    };
  },
  mounted() {
    this.lang = window.language;
    if (this.lang === "en") {
      this.lang2 = "fr";
    }
  },
  setup() {
    const { play } = useSound(buttonClick);
    const onSound = useSound(gameStart);

    return {
      play,
      onSound,
    };
  },
  methods: {
    openLang() {
      this.play();
      this.open = !this.open;
    },
    changeLang() {
      this.play();
      if (this.lang === "fr") {
        this.lang = "en";
        this.lang2 = "fr";
      } else {
        this.lang = "fr";
        this.lang2 = "en";
      }
      this.open = false;
      window.location = "?lang=" + this.lang;
    },
  },
};
</script>
<style lang="scss" scoped></style>
