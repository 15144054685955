import copydeck from "./copydeck.csv";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const lang = urlParams.get("lang");
let language = "fr";

if (lang && lang === "en") {
  language = "en";
}

console.log(language);

window.language = language;

export default {
  get(KEY) {
    var __FOUND = copydeck.find(function (cd, index) {
      if (cd.variable == KEY) {
        return true;
      }
    });

    if (__FOUND) {
      if (language === "fr" && __FOUND.valeur_fr != null)
        return __FOUND.valeur_fr.trim();
      else if (language === "en" && __FOUND.valeur_en != null)
        return __FOUND.valeur_en.trim();
    } else {
      return KEY;
    }
  },
};
