import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "./assets/styles/index.scss";

import { InlineSvgPlugin } from "vue-inline-svg";
Vue.use(InlineSvgPlugin);

import PortalVue from "portal-vue";
Vue.use(PortalVue);

import VAnimateCss from "v-animate-css";
Vue.use(VAnimateCss);

import { Simplert } from "vue2-simplert-plugin";
import "vue2-simplert-plugin/dist/vue2-simplert-plugin.min.css";
Vue.use(Simplert);

import VueNumber from "vue-number-animation";
Vue.use(VueNumber);

import CopyDeck from "@/json/copydeck.js";
Vue.mixin({
  methods: {
    $CD(key) {
      return CopyDeck.get(key);
    },
  },
});

Vue.config.productionTip = false;

console.log("process.env.NODE_ENV", process.env.NODE_ENV, process.env);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
